import axios from 'axios'
//import env from "react-dotenv"

const apis = {
  fetchAirlines: async () => {
    const config = {
      withCredentials: false,
    }
    const res = await axios.get(
      //"https://api.datascalp.com/api/airlines/fetch",
      `${process.env.REACT_APP_ADDRESS}/api/airlines/fetch`,
      //"http://172.28.30.47:8080/api/airlines/fetch",
      config
    )
    return res.data
  },
  fetchUKAirlines: async () => {
    const config = {
      withCredentials: false,
    }
    const res = await axios.get(
      //"https://api.datascalp.com/api/airlines/fetchuk",
      `${process.env.REACT_APP_ADDRESS}/api/airlines/fetchuk`,
      //"http://172.28.30.47:8080/api/airlines/fetch",
      config
    )
    return res.data
  },
  createIncident: async (data) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    
    const res = await axios.post(
      //"https://api.datascalp.com/api/incidents/create",
      `${process.env.REACT_APP_ADDRESS}/api/incidents/create`,
      data,
      config
    )
    console.log('resultzz', data, res)
    return res.status
  },
}

export default apis
